<template>
	<div class="contentCard">
	   <Row :gutter="16">
			<Col span="24">
				<div class="controllRow" style="margin-bottom: 1rem;">
				  <Dropdown id="branchDropdown" trigger="custom" :visible="visible" placement="bottom-start" style="float: left;">
						<Button type="default" @click="visible = !visible">
							<Icon type="ios-git-branch" />
							{{branchName}}
							<Icon type="ios-arrow-down"></Icon>
						</Button>	
						<DropdownMenu slot="list" style="width: 20rem;">
							<div style="margin: 2px 0; text-align: left; padding-left: 16px;">
								<span style="font-weight: 300;">Switch branches</span> 
							</div>
							<Divider style="margin: 0rem;"/>
							<div style="padding: 0.5rem;">
								<Input v-model="searchInput" :placeholder="searchPlaceholder" @on-change="branchSearch"/>
							</div>
							<Tabs type="card">
								<TabPane label="Branches">
									<Table highlight-row :columns="columns1" :data="data1" :show-header="false" @on-row-click="refreshCode"></Table>
								</TabPane>
								<!-- <TabPane label="Tags">
									<Table highlight-row :columns="columns1" :data="data1" :show-header="false"></Table>
								</TabPane> -->
							</Tabs>
						</DropdownMenu>
					</Dropdown>
					<Button type="text" style="float: left; padding-right: 0rem;">
						<Icon type="ios-git-branch" />
						{{branchNum}} Branches
					</Button>
<!-- 					<Button type="text" style="float: left;">
						<Icon type="ios-pricetag-outline" />
						{{TagsNum}} Tags
					</Button> -->
					<Dropdown trigger="click" placement="bottom-end" style="float: right;">
						<Button type="success" >
							<Icon type="md-download" />
							Code
							<Icon type="ios-arrow-down"></Icon>
						</Button>
						<DropdownMenu slot="list" style="width: 21.875rem;">
							<div style="margin: 2px 0; text-align: left; padding-left: 16px;">
								<Icon type="ios-cloud-download" />
								<span style="font-weight: 300; margin-left: 0.5rem;">Clone</span> 
							</div>
							<Tabs style="padding: 1rem; padding-top: 0rem; padding-bottom: 0.5rem;">
								<TabPane label="HTTPS">
									<Input readonly v-model="HTTPSAddress" style="width: 17.875rem; float: left;" id="clonePaneInput"/>
<!--									<input id="httpsClone"/>-->
									<Button icon="md-copy" @click="urlCopy" style="border-radius: 0 5px 5px 0; float: left; left: -0.125rem;"></Button>
									<!-- <span style="float: left; margin-top: 0.5rem; font-size: 0.75rem; color: red;">Prohibit changing the tags of the project!</span> -->
								</TabPane>
							</Tabs>
						</DropdownMenu>
					</Dropdown>
				</div>
				<Row style="padding: 1rem; border-radius: 5px 5px 0 0; background-color: #e2eefd; border: #c8e1ff solid 0.0625rem;">
					<Col span="12" style="text-align: left;">
						<Icon type="ios-arrow-back" v-if="fileUrl != '.'" @click="backFolder"/>
					</Col>
					<Col span="12" style="text-align: right;">
						<!-- <span style="font-size: 1rem; margin-right: 0.5rem;">{{ lastCommitTime }} </span> -->
						<!-- <Icon type="ios-timer-outline" /> -->
						<!-- <span style="font-size: 1rem;">{{commitsCount}} commits</span> -->
					</Col>
				</Row>
				<div class="codeTable">
					<Table :columns="codeTableColoums" :data="codeTableData" :show-header="false">
						<template slot-scope="{ row }" slot="fileName">
							<Button type="text" v-if="row.isFolder" ghost style="color: #515a6e; padding-left: 0rem;" @click="enterFolder(row)">
								<Icon type="ios-folder-outline" color="#0051ff" style="margin-right: 0.5rem;"/>
								{{row.fileName}}
							</Button>
							<span v-if="!row.isFolder" >
								<Icon type="ios-document-outline" style="margin-right: 0.5rem;"/>
								{{row.fileName}}
							</span>
						</template>
					</Table>					
				</div>
			</Col>
<!-- 			<Col span="6">
				<div class="discription">
					<h2 class="disTitle">
						About
					</h2>
					<div class="disContent">
						No description, website, or topics provided.
					</div>
				</div>
				<Divider />
				<div class="discription">
					<h2 class="disTitle">
						Releases
					</h2>
					<div class="disContent">
						No releases published
					</div>
				</div>
				<Divider />
				<div class="discription">
					<h2 class="disTitle">
						Packages
					</h2>
					<div class="disContent">
						No packages published
					</div>
				</div>
			</Col> -->
		</Row>
	</div>
</template>

<script>
export default{
	data(){
		return{
			branchName: 'branchName',
			branchNum: 3,
			TagsNum: 0,
			searchInput: '',
			searchPlaceholder: 'Find a branch...',
			HTTPSAddress: '',
			projectName: '',
			lastCommitName: 'userName',
			message: 'message',
			lastCommitTime: 'lastCommitTime',
			commitsCount: 10,
			fileUrl: '.',
			visible: false,
			columns1: [
				{
					title: 'Name',
					key: 'branchName'
				},
				{
					title: 'IsDefault',
					key: 'IsdDfault',
					className: 'defaultColumn',
					width: 64
				},
			],
			data1: [
				{
					branchName: 'main',
					IsdDfault: '(default)'
				},
				{
					branchName: 'frontend',
					IsdDfault: ''
				},
				{
					branchName: 'backend',
					IsdDfault: ''
				},
			],
			branchData: [
				{
					branchName: 'main',
					IsdDfault: '(default)'
				},
				{
					branchName: 'frontend',
					IsdDfault: ''
				},
				{
					branchName: 'backend',
					IsdDfault: ''
				},
			],
			codeTableColoums:[
				{
					title: 'fileName',
					slot: 'fileName'
				},
				{
					title: 'message',
					key: 'message'
				},
				{
					title: 'commitTime',
					key: 'commitTime',
					width: 120,
					align: "right"
				},
			],
			codeTableData:[
				// {
				// 	fileName:'algorithm',
				// 	message: 'add readme',
				// 	commitTime: '4 days ago',
				// 	isFolder: true
				// },
				// {
				// 	fileName:'backend',
				// 	message: 'gitignore changed',
				// 	commitTime: '17 days ago',
				// 	isFolder: true
				// },
				// {
				// 	fileName:'frontend',
				// 	message: 'add readme',
				// 	commitTime: '4 days ago',
				// 	isFolder: true
				// },
				// {
				// 	fileName:'.gitignore',
				// 	message: 'gitignore changed',
				// 	commitTime: '17 days ago',
				// 	isFolder: false
				// },
				// {
				// 	fileName:'plan.md',
				// 	message: 'add document',
				// 	commitTime: '21 days ago',
				// 	isFolder: false
				// },
				// {
				// 	fileName:'README.md',
				// 	message: 'initial commit',
				// 	commitTime: '22 days ago',
				// 	isFolder: false
				// },
			]
		}
	},
	methods:{
		enterFolder(row){
			console.log(this.branchName)
			this.fileUrl += '/'+row.fileName;
			var Tself = this;
			this.$axios({
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'get',
				url: '/pit/getAllFile?projectId='+Tself.$cookies.get('contributionId')+'&relPath='+this.fileUrl+'&branch='+this.branchName,
			})
			.then(function (response) {
				var data2 = response.data;
				Tself.codeTableData = [];
				for(var i = 0; i< data2.length; i++){
					Tself.codeTableData.push({
						fileName: data2[i].file,
						message: data2[i].message,
						commitTime: '',
						isFolder: data2[i].dir
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
		},
		backFolder(){
			var lastIndex = this.fileUrl.lastIndexOf('/');
			this.fileUrl = this.fileUrl.substring(0,lastIndex);
			var Tself = this;
			this.$axios({
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'get',
				url: '/pit/getAllFile?projectId='+Tself.$cookies.get('contributionId')+'&relPath='+this.fileUrl+'&branch='+this.branchName,
			})
			.then(function (response) {
				var data2 = response.data;
				Tself.codeTableData = [];
				for(var i = 0; i< data2.length; i++){
					Tself.codeTableData.push({
						fileName: data2[i].file,
						message: data2[i].message,
						commitTime: '',
						isFolder: data2[i].dir
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
		},
		branchSearch(){
			this.data1 = []
			if(this.searchInput.length == 0){
				this.data1 = this.data1.concat(this.branchData);
				return;
			}
			for(var i = 0; i< this.branchData.length; i++){
				if(this.branchData[i].branchName.indexOf(this.searchInput) != -1){
					this.data1.push(this.branchData[i]);
				}
			}
		},
		urlCopy(){
			// var httpsURL = document.getElementById("httpsClone");
			// httpsURL.value = this.HTTPSAddress;
			// httpsURL.select();
			// document.execCommand("Copy");
			// this.$Message.success('Copy!');
			let httpsURL = document.createElement("input");
			httpsURL.value = this.HTTPSAddress;
			document.body.appendChild(httpsURL);
			httpsURL.select();
			document.execCommand("Copy");
			this.$Message.success('Copy!');
			httpsURL.remove();
		},
		refreshCode(branchName){
			if(typeof branchName == 'object'){
				branchName = branchName.branchName;
			}
			this.branchName = branchName;
			this.visible = false;
			var Tself = this;
			this.$axios({
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'get',
				url: '/pit/getAllFile?projectId='+Tself.$cookies.get('contributionId')+'&relPath=.'+'&branch='+branchName,
			})
			.then(function (response) {
				var data2 = response.data;
				Tself.codeTableData = [];
				for(var i = 0; i< data2.length; i++){
					Tself.codeTableData.push({
						fileName: data2[i].file,
						message: data2[i].message,
						commitTime: '',
						isFolder: data2[i].dir
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
		},
		init(){
			var val = this.$route.query.id;
			this.projectId = val;
			var Tself = this;
			this.$axios({
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'get',
				url: '/pit/getUrl?contributionId='+Tself.$cookies.get('contributionId'),
			})
			.then(function (response) {
				var data = response.data;
				Tself.HTTPSAddress = data;
			})
			.catch(function (error) {
				console.log(error);
			});
			
			this.$axios({
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'get',
				url: '/pit/getBranchList?Id='+Tself.$cookies.get('contributionId'),
			})
			.then(function (response) {
				Tself.data1 = [];
				Tself.branchData = [];
                var data=[]
                for(var i=0;i<response.data.length;i++){
                    if(response.data[i].length<30){
                        data.push(response.data[i])
					}
				}

				Tself.branchNum = data.length;
				for(var i = 0; i< data.length; i++){
					if(i == 0){
						Tself.data1.push({
							branchName: data[i].substr(11),
							IsdDfault: '(default)',
							_highlight: true
						})
						Tself.branchData.push({
							branchName: data[i].substr(11),
							IsdDfault: '(default)',
							_highlight: true
						})
					}
					else{
						Tself.data1.push({
							branchName: data[i].substr(11),
							IsdDfault: ''
						})
						Tself.branchData.push({
							branchName: data[i].substr(11),
							IsdDfault: ''
						})
					}
				}
				Tself.refreshCode(Tself.data1[0].branchName);
			})
			.catch(function (error) {
				console.log(error);
			});
		}
	},
	mounted() {
		this.init();
	}
}
</script>

<style>
.controllRow{
	line-height: 2rem;
	height: 2.125rem;
}
.discription{
	padding: 0 2rem;
	text-align: left;
	margin-bottom: 1rem;
}
.disTitle{
	font-size: 1rem;
	margin-bottom: 1rem;
}
.disContent{
	font-size: 0.75rem;
	font-style: italic;
}
.ivu-tabs-bar{
	padding-left: 0.5rem;
	margin-bottom: 0.125rem;
}
.defaultColumn > .ivu-table-cell{
	padding: 0;
}
.codeTable{
	border-left: #c8e1ff solid 0.0625rem;
	border-right: #c8e1ff solid 0.0625rem;
	border-bottom: #c8e1ff solid 0.0625rem;
	border-radius: 0 0 4px 4px;
}
#clonePaneInput > input{
	border-radius: 5px 0 0 5px !important;
}
#branchDropdown > .ivu-select-dropdown{
	transform-origin: center top !important;
	top: 33px !important;
}
#httpsClone{
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: -10;
}
</style>
